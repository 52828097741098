<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Pedido - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col sm="12" md="9"></b-col>
             
              <b-col sm="6" md="3">
                <b-input-group>
                  <b-form-input type="search" v-model="search" class="form-control" ></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="ListOrder">
                      <b-icon icon="search"></b-icon
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="8%" class="text-center">Nro</th>
                    <th width="35%" class="text-center">Cliente</th>
                    <th width="7%" class="text-center">Mesa</th>
                    <th width="8%" class="text-center">F. Pago</th>
                    <th width="7%" class="text-center">Moneda</th>
                    <th width="7%" class="text-center">Total</th>
                    <th width="10%" class="text-center">Moso</th>
                    <th width="7%" class="text-center">Estado</th>
                    <th width="8%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date }}</td>
                    <td class="text-center"> {{ item.code }}</td>
                    <td class="text-left"> {{ item.client_name + " - " +item.client_document_number}}</td>
                    <td class="text-left"> {{ item.table_name }}</td>
                    <td class="text-left"> {{ item.payment_method == "01" ? "Contado":"Crédito"}}</td>
                    <td class="text-center"> {{ item.coin }}</td>
                    <td class="text-right"> {{ item.total }}</td>
                    <td class="text-left"> {{ item.moso_name }}</td>
                     <td class="text-center">

                      <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                      <b-badge v-if="item.state == 1" variant="warning">Pendiente</b-badge>
                      <b-badge v-if="item.state == 2" variant="success">Finalizado</b-badge>
                      
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('OrderEdit') && item.state == 2" @click="ConfirmReOpenOrder(item.id_order)" >Reabrir Mesa</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('OrderEdit') && item.state == 2" @click="EditOrder(item.id_order)" >Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('OrderView') && item.state == 2" @click="ViewOrder(item.id_order)" >Ver</b-dropdown-item >
                        <b-dropdown-item v-if="Permission('OrderEdit') && (item.state == 1 || item.state == 2)" @click="Invoice(item.id_order)" >Facturar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('OrderDelete') && item.state == 1"  @click="ConfirmDeleteOrder(item.id_order)" >Eliminar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage"  v-on:input="ListOrder" :total-rows="rows" :per-page="perPage"  align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
export default {
  name: "UsuarioList",
  components:{
  },
  data() {
    return {
      module:'Order',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      search: "",
      data_table: [],
    };
  },
  mounted() {
    this.ListOrder();
  },
  methods: {
    ListOrder,
    EditOrder,
    ViewOrder,
    ConfirmDeleteOrder,
    DeleteCategory,
    Permission,
    Invoice,

    ConfirmReOpenOrder,
    ReOpenOrder,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ListOrder() {
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "order/list/" + search + "?page=" + this.currentPage;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module, role: 1, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function EditOrder(id_order) {
  this.$router.push({
    name: "OrderEdit",
    params: { id_order: je.encrypt(id_order) },
  });
}

function ViewOrder(id_order) {
  this.$router.push({
    name: "OrderView",
    params: { id_order: je.encrypt(id_order) },
  });
}

function Invoice(id_order) {
  this.$router.push({
    name: "SaleAddOrder",
    params: { id_order: je.encrypt(id_order) },
  });
}

// Confirmar eliminar
function ConfirmDeleteOrder(id_order) {
  Swal.fire({
    title: "Esta seguro de eliminar el pedido ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteCategory(id_order);
    }
  });
}

// eliminar usuario
function DeleteCategory(id_order) {
  let me = this;
  let url = this.url_base + "order/delete/" + id_order;
  axios({
    method: "delete",
    url: url,
    headers: {token: this.token,module: this.module,role: 4},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_order).indexOf(response.data.result.id_order);
        me.data_table.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}



function ConfirmReOpenOrder(id_order) {
  Swal.fire({
    title: "Esta seguro de reabrir la mesa ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.ReOpenOrder(id_order);
    }
  });
}

// eliminar usuario
function ReOpenOrder(id_order) {
  let me = this;
  let url = this.url_base + "order/re-open/" + id_order;
  axios({
    method: "get",
    url: url,
    headers: {token: this.token,module: this.module,role: 3},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_order).indexOf(response.data.result.id_order);
        me.data_table[index].state = 1;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
